import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';

// Footer styled
export const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  h2 {
    font-size: 18px;
  }

  span {
    color: #999;
  }

  p {
    color: #606060;
  }

  h1 {
    display: flex;
    font-size: 20px;
    line-height: 26px;
    justify-content: center;
    align-items: center;
  }

  ul,
  ol {
    padding: 15px;
  }

  img {
    width: 100%;
  }

  blockquote {
    border-left: 3px solid var(--text-color);
    padding-left: ${getMargin('base')};
    font-size: 20px;
    line-height: 30px;
    font-style: italic;
    color: #808080;
    margin-left: 0;
  }

  blockquote + cite,
  blockquote > p > cite {
    display: block;
    margin-top: 20px;
    font-size: 16px;
  }
`;

export const SpanWrapper = styled.div`
  text-align: center;
  display: inline;
  margin-bottom: ${getMargin('base')};

  > *:not(:last-child):after {
    content: '/';
    font-size: 13px;
    font-weight: 500;
    padding: 0 10px 0 10px;
  }
`;
