import { graphql, Link } from 'gatsby';
import { GatsbyImage, getSrc } from 'gatsby-plugin-image';
import { kebabCase } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import { Button } from '../components/atoms/Buttons';
import { Main } from '../components/molecules/Main';
import { BlogContent } from '../components/organisms/BlogContent/BlogContent';
import { PopulatedBlogAside } from '../components/organisms/PopulatedBlogAside';
import { MainLayout } from '../components/templates/MainLayout/MainLayout';

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const content = post.html;
  const contentComponent = HTMLContent;
  const description = post.frontmatter.description;
  const helmet = (
    <Helmet titleTemplate="%s | Blog">
      <title>{`${post.frontmatter.title}`}</title>
      <meta name="description" content={`${post.frontmatter.description}`} />
    </Helmet>
  );

  const image = post.frontmatter.featuredimage;
  const tags = post.frontmatter.tags;
  const title = post.frontmatter.title;
  const date = post.frontmatter.date;

  const PostContent = contentComponent || Content;

  return (
    <Layout>
      {helmet || ''}
      <Main withVerticalSpacing fillHeight>
        <MainLayout aside={<PopulatedBlogAside />}>
          <BlogContent
            tags={tags}
            description={description}
            date={date}
            title={title}
            image={image}
          >
            <PostContent content={content} />
          </BlogContent>
        </MainLayout>
      </Main>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        title
        description
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
