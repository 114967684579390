import React from 'react';

import useSiteMetadata from '../../../hooks/graphql/useSiteMetadata';
import useSiteTags from '../../../hooks/graphql/useSiteTags';
import { BlogAside } from '../../molecules/BlogAside/BlogAside';

export const PopulatedBlogAside: React.FC = () => {
  const tags = useSiteTags();
  const metadata = useSiteMetadata();

  return (
    <BlogAside
      popularTags={tags.map((tag) => ({
        tag: tag.name,
        slug: '/',
      }))}
      about={{
        title: metadata.title,
        presentation: metadata.description,
      }}
    />
  );
};
