import React from 'react';
import styled, { css } from 'styled-components';

import {
  getBorderRadius,
  getColor,
  getMargin,
  getScaffoldingSize,
} from '../../../styles/helper/stylesHelper';
import { Device, Responsive } from '../../../styles/theme/responsive';

export interface StyledMainProps {
  noTopPart: boolean;
  withMarginTop: boolean;
  fillHeight: boolean;
  customCss?: React.CSSProperties;
  background?: string;
}

export const StyledMain = styled.main<StyledMainProps>`
  width: 100%;
  position: relative;
  z-index: 1;
  background-color: ${getColor('white')};
  border-top-left-radius: ${({ noTopPart }) => (noTopPart ? 'O' : getBorderRadius('block'))};
  border-top-right-radius: ${({ noTopPart }) => (noTopPart ? 'O' : getBorderRadius('block'))};

  margin-top: ${({ withMarginTop }) => (withMarginTop ? getMargin('small') : '0')};

  ${({ fillHeight }) =>
    fillHeight &&
    css`
      min-height: calc(
        100vh - ${getScaffoldingSize('footerHeight')} - ${getScaffoldingSize('navHeight')}
      );
    `}

  @media ${Responsive.lessThan(Device.mobile)} {
    margin-top: ${({ withMarginTop }) => (withMarginTop ? getMargin('small') : '0')};
  }

  ${({ background }) =>
    background &&
    css`
      &::after {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #00000070;
        opacity: 1;
        transition: all 0.3s;
      }

      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: url(${background});
        background-position: center;
        background-size: cover;
        opacity: 1;
      }
    `}
`;
