import { GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';
import { kebabCase } from 'lodash';
import React from 'react';

import { dateMonth } from '../../../utils/date.utils';
import { Button } from '../../atoms/Buttons';

import { BlogWrapper, SpanWrapper } from './styled';

interface BlogContentProps {
  children: React.ReactNode;
  title: string;
  description: string;
  date: string;
  image: ImageDataLike;
  tags: string[] | null;
}

export const BlogContent: React.FC<BlogContentProps> = ({
  children,
  title,
  description,
  date,
  image,
  tags,
}) => {
  const mainTag = tags && tags.length > 0 ? tags[0] : null;

  return (
    <BlogWrapper>
      <h1>{title}</h1>
      <SpanWrapper>
        <span>{dateMonth(date)}</span>
        <span>{mainTag && mainTag}</span>
      </SpanWrapper>
      {image && <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={''} />}
      <p>{description}</p>

      {children}

      {tags && tags.length ? (
        <div>
          {tags.map((tag) => (
            <Button to={`/tags/${kebabCase(tag)}/`} children={tag} key={tag.slug} />
          ))}
        </div>
      ) : null}
    </BlogWrapper>
  );
};
