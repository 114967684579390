import React from 'react';

import { Button } from '../../atoms/Buttons';
import { Section } from '../../atoms/Section';

import { About, AboutDiv, BlogAsideWrapper, BloggingDiv, Container, Title } from './styled';

export interface BlogAsideProps {
  popularTags?: {
    tag: string;
    slug: string;
  }[];
  about?: {
    title: string;
    presentation: string;
  };
}

export const BlogAside: React.FC<BlogAsideProps> = ({ popularTags, about }) => {
  return (
    <BlogAsideWrapper>
      <Section>
        <Container>
          {about && (
            <AboutDiv>
              <Title>{about.title}</Title>
              <About>{about.presentation}</About>
            </AboutDiv>
          )}
          <BloggingDiv>
            <Title>Tags</Title>
            {popularTags &&
              popularTags
                .map((tag) => <Button href={tag.slug} children={tag.tag} key={tag.slug} />)
                .slice(0, 6)}
          </BloggingDiv>
        </Container>
      </Section>
    </BlogAsideWrapper>
  );
};
