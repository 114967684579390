import React from 'react';

import { Section } from '../../atoms/Section';

import { StyledMain } from './styled';

interface MainProps {
  children: React.ReactNode;
  noTopPart?: boolean;
  withVerticalSpacing?: boolean;
  withMarginTop?: boolean;
  noGutterTop?: boolean;
  background?: string;
  fillHeight?: boolean;
  fullWidth?: boolean;
}

export const Main: React.FC<MainProps> = ({
  noTopPart = false,
  children,
  withVerticalSpacing = false,
  withMarginTop = false,
  noGutterTop = false,
  background,
  fillHeight = false,
  fullWidth = false,
}) => {
  return (
    <StyledMain
      noTopPart={noTopPart}
      withMarginTop={withMarginTop}
      background={background}
      fillHeight={fillHeight}
    >
      <Section
        noGutterTop={noTopPart || noGutterTop}
        withVerticalSpacing={withVerticalSpacing}
        full={fullWidth}
      >
        {children}
      </Section>
    </StyledMain>
  );
};
