import styled from 'styled-components';

import { getMargin } from '../../../styles/helper/stylesHelper';

// BlogAside styled
export const BlogAsideWrapper = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: ${getMargin('base')};
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: ${getMargin('base')};
  flex-direction: column;
`;

export const BloggingDiv = styled.div`
  flex: 1;
`;

export const AboutDiv = styled.div`
  flex: 1;
`;

export const About = styled.p``;

export const Title = styled.p`
  margin-bottom: 15px;
  font-size: 13px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
`;
